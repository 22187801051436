import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {ApplicationState} from '../../../../store'
import {loadLastClassRequest} from '../../../../store/ducks/component/actions'
import {User} from '../../../../store/ducks/me/types'
import Loading from '../../../loading'
import _ from 'lodash'
import { loadUserRequest } from '../../../../store/ducks/users/actions'
const MOMENT = require('moment')

interface handleCloseProps {
  handleClose: () => void
  child: User
}

const InfoUser = ({handleClose, child}: handleCloseProps) => {
  // const {id} = useParams();
  const dispatch = useDispatch()
  const [array,setArray] = useState<any>([])

  useEffect(() => {
    dispatch(loadLastClassRequest(child.id!))
    dispatch(loadUserRequest(child.id!))
  }, [child.id])



  const component = useSelector((state: ApplicationState) => state.component)
  const users = useSelector((state: ApplicationState) => state.users)

 

  useEffect(() => {
    console.log('USERS****************', users)
    if(users.user.id === +child.id!){


      console.log("asdasdasd: ", users.user.id)
      const sort = _.orderBy(
        users.user.completed,
        ['component.parent.parent.order', 'component.order', 'component.id'],
        ['desc', 'asc', 'asc']
      )
      const group: any = _.groupBy(sort, 'component.parent.parent.id')
      let groupArray: any = Object.keys(group).map((key) => group[key])
      console.log('groupArray', groupArray)
      setArray(groupArray)

    }
      
  }, [users.loadingUser])

console.log('array', array)



  let urlLastClass: string | undefined = ''
  let checkLastClass = component.lastclass?.extras?.filter(
    (extra: any) => extra.keyExtra === 'url'
  )[0] //Checa se tem o 'extra' de url.
  if (checkLastClass) urlLastClass = checkLastClass.valueExtra
  console.log('component', component)

  // var data = new Date(apiResponse.createdAt*1000);
  let createdAt = MOMENT(child.createdAt) //.format('DD/MM/YYYY HH:mm')
  var now = MOMENT(Date()) //.format('DD/MM/YYYY HH:mm')
  var src = /^(\d{3})(\d{3})(\d{3})(\d{2})$/
  var dst = '$1.$2.$3-$4'
  var cpfformat = child.cpf?.replace(src, dst)

  console.log('child', child)


 


  // console.log('sort', sort)
  // console.log('group', group)
  // console.log('groupArray', groupArray)

  
  //return <div>oi</div>
  return (
    <>
      <div className='row g-5 gx-xxl-12'>
        <div className='col-xxl-4'>
          <img
            //src={users.user.image}
            //src={ image?.includes('https://') ? image : '../../files/' + image}
            src={
              child.image?.includes('https://')
                ? child.image
                : 'https://app.esculturalabial.com.br/files/' + child.image
            }
            style={{width: '100%'}}
            onError={({currentTarget}) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = 'https://app.esculturalabial.com.br/files/guestuser.jpg'
            }}
          />
        </div>
        <div className='col-xxl-8'>
        
          <h1>{child.name}</h1>
          <div>
            <span className='text-dark fw-bolder fs-6'>Refêrencia:</span> EL{child.id}
          </div>
          <div>
            <span className='text-dark fw-bolder fs-6'>Email:</span> {child.email}
          </div>
          <div>
            <span className='text-dark fw-bolder fs-6'>WhatsApp:</span> {child.whatsapp}
          </div>
          <div>
            <span className='text-dark fw-bolder fs-6'>CPF:</span> {cpfformat}
          </div>
          <div>
            <span className='text-dark fw-bolder fs-6'>Endereço:</span> {child.address},{' '}
            {child.addressNumber}, {child.addressDistrict} - {child.city?.name} /{' '}
            {child.state?.state} - {child.postalCode}
          </div>
          <span className='text-dark fw-bolder fs-6'>Última renovação:</span>{' '}
          {createdAt.format('DD/MM/YYYY HH:mm')}
          {' / '}
          {now.diff(createdAt, 'years', true) > 1 ? 'RENOVAÇÃO' : 'NO PRAZO'}
          {' / '}
          {(now.diff(createdAt, 'years', true) * 100).toFixed(2)}%{/* {component.error} */}
          {component.loadingLastClass ? (
            <Loading />
          ) : (
            <div>
              <span className='text-dark fw-bolder fs-6'>Última aula assistida:</span>{' '}
              {component.lastclass?.parent?.parent?.name} - {component.lastclass?.parent?.name} -{' '}
              {component.lastclass?.name}
            </div>
          )}
          <span className='text-dark fw-bolder fs-6'>Último login:</span>{' '}
          {MOMENT(child.lastLoginAt).format('DD/MM/YYYY HH:mm')}
          <div>TURMA: {child.numTurma}</div>
          <div>
            {child.cart?.map((item: any) => (
              <>
              <div>Método: {item.paymentmethod}</div>
              <div>Parcelas: {item.installments}</div>
              <div>Preço base: {item.price}</div>
              <div>Mercado pago: {item.idreference}</div>
              </>
            ))}
          </div>
        </div>
        <div>
          <h1>Progresso do aluno</h1>
          {users.loadingUser && <Loading/> }
           {array.map((course: any) => (
            <>
              <h2 className='pt-5 pb-5'>{course[0].component.parent.parent.name}</h2>

              <div className='col-xxl-12'>
                <div className='table-responsive'>
                 
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                   
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-50px'>Módulo</th>
                        <th className='min-w-50px'>Aula</th>
                        <th className='min-w-140px'>Duração</th>
                        <th className='min-w-140px'>Visto</th>
                        <th className='min-w-140px'>%</th>
                        <th className='min-w-140px'>✩</th>
                      </tr>
                    </thead>
                    
                    <tbody>
                      {course.map((aula: any) => (
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {aula.component.parent.name}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {aula.component.name}
                              </div>
                            </div>
                          </td>
                          <td>
                            {MOMENT.duration(aula.component?.duration, 'seconds').format(
                              'hh:mm:ss',
                              {
                                trim: false,
                              }
                            )}
                          </td>
                          <td>
                            {MOMENT.duration(aula.timeWatched, 'seconds').format('hh:mm:ss', {
                              trim: false,
                            })}
                          </td>
                          <td>
                            {Math.round((aula?.timeWatched! / aula.component?.duration) * 100) <=
                            50 ? (
                              <span className='text-danger'>
                                {Math.round((aula?.timeWatched! / aula.component?.duration) * 100)}%
                              </span>
                            ) : (
                              <span className='text-success'>
                                {Math.round((aula?.timeWatched! / aula.component?.duration) * 100)}%
                              </span>
                            )}
                          </td>
                          <td>
                            {aula.rate === 0 || aula.rate === null ? (
                              '-'
                            ) : aula.rate! <= 3 ? (
                              <span className='text-danger'>{aula.rate}</span>
                            ) : (
                              <span className='text-success'>{aula.rate}</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ))} 


          
        </div>
      </div>
    </>
  )
}
export default InfoUser
